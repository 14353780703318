// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';


// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/dashboard',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
       
    ]
};

export default MainRoutes;
