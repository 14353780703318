
import { Outlet } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';

import AppBar from 'ui-component/extended/AppBar';
import FooterSection from '../Common/FooterSection';
import { Box, Container, CssBaseline} from '@mui/material';

import useConfig from 'hooks/useConfig';

import LAYOUT_CONST from 'constant';

// ==============================|| MINIMAL LAYOUT ||============================== //

const SectionWrapper = styled('div')({
    paddingTop: 100,
    paddingBottom: 100
});





// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, layout }) => ({
    ...theme.typography.mainContent,
    marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 88 : 135
}));


const MinimalLayout = () => {
    const theme = useTheme();

    const { container, layout } = useConfig()


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar />

            {/* main content */}
            <Main theme={theme} layout={layout}>
                <Container maxWidth={container ? 'lg' : false} {...(!container && { sx: { px: { xs: 0 } } })}>
                    <Outlet />
                </Container>
                <SectionWrapper sx={{ bgcolor: theme.palette.mode === 'dark' ? 'background.default' : 'dark.900', pb: 0 }}>
                <FooterSection />
            </SectionWrapper>
            </Main>
           
        </Box>
    );

}

export default MinimalLayout;
