import { createBrowserRouter } from 'react-router-dom';

// routes
import PublicRoutes from './PublicRoutes';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';


// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter([PublicRoutes, LoginRoutes, AuthenticationRoutes, MainRoutes], { basename: process.env.REACT_APP_BASE_NAME });
export default router;
