import { lazy } from 'react';
// project imports
import MinimalLayout from 'layout/MinimalLayout';

import { SCHEDULE_CALL_PATH } from 'config';

import Loadable from 'ui-component/Loadable';
const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));
const WhatWeDo = Loadable(lazy(() => import('views/pages/whatwedo')));
const HowWeThink = Loadable(lazy(() => import('views/pages/howwethink')));
const Industries = Loadable(lazy(() => import('views/pages/industries')));
const Calendar = Loadable(lazy(() => import('application/schedule')));
// ==============================|| MAIN ROUTING ||============================== //
const PublicRoutes = {
    path: '/',
    element: (
       <MinimalLayout/>
    ),
    children: [
        {
            path: '/',
            element: <PagesLanding />
        },
        {
            path: '/whatwedo',
            element: <WhatWeDo />
        },
        {
            path: '/industries',
            element: <Industries />
        },
        {
            path: '/howwethink',
            element: <HowWeThink />
        },
        {
            path: SCHEDULE_CALL_PATH,
            element: <Calendar />
        }
    ]
};

export default PublicRoutes;
