// material-ui
import { useTheme, /*styled*/ } from '@mui/material/styles';
import { Box, Container, Divider, Grid, /* Link,*/ Stack, Typography } from '@mui/material'; // Divider

// project import


// assets
//import Dribble from 'assets/images/landing/footer-dribble.png';
//import Freepik from 'assets/images/landing/footer-freepik.png';
//import Awards from 'assets/images/landing/footer-awards.png';

//import { Instagram, LinkedIn, Twitter, YouTube } from '@mui/icons-material/';

//import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';

// Link - custom style
/*const FooterLink = styled(Link)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.text.hint,
    '&:hover': {
        color: theme.palette.primary.main
    },
    '&:active': {
        color: theme.palette.primary.main
    }
}));*/

// =============================|| LANDING - FOOTER SECTION ||============================= //

const FooterSection = () => {
    const theme = useTheme();
    const textColor = theme.palette.mode === 'dark' ? 'text.secondary' : 'text.hint';

    const dividerSX = {
        borderImageSlice: 1,
        borderImageSource: `linear-gradient(90deg, rgba(255, 255, 255, 0) -0.01%, rgba(255, 255, 255, 0.56) 51.97%, rgba(255, 255, 255, 0.03) 99.99%)`,
        opacity: 0.5
    };

    return (
        <>
            <Container sx={{ mb: 15 }}>
                <Grid container spacing={6}>

                    <Grid item xs={12}>
                        <Grid container spacing={12}>
                            <Grid item xs={12} md={4}>
                                <Stack spacing={{ xs: 2, md: 5 }}>
                                    <Typography variant="h4" color={textColor} sx={{ fontWeight: 500 }}>
                                        About 47NW Capital
                                    </Typography>
                                    <Typography variant="body2" color={textColor}>
                                        Based in Seattle, we are an investment firm that specializes in acquiring
                                        companies located in the Pacific Northwest. With a focus on long-term
                                        growth opportunities, we are committed to acquiring a longterm stake in
                                        companies to help them reach their full potential.
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={{ xs: 5, md: 2 }}>
                                    {/*<Grid item xs={6} sm={3}>
                                        <Stack spacing={{ xs: 3, md: 5 }}>
                                            <Typography variant="h4" color={textColor} sx={{ fontWeight: 500 }}>
                                                Culture
                                            </Typography>
                                            <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                                                <FooterLink href="https://mui.com/store/license/" target="_blank" underline="none">
                                                    Our Principles
                                                </FooterLink>
                                                <FooterLink href="https://mui.com/store/license/" target="_blank" underline="none">
                                                    What We Think
                                                </FooterLink>
                                                <FooterLink href="https://mui.com/store/license/" target="_blank" underline="none">
                                                    Why is 47NW Different?
                                                </FooterLink>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Stack spacing={{ xs: 3, md: 5 }}>
                                            <Typography variant="h4" color={textColor} sx={{ fontWeight: 500 }}>
                                                Research & Insights
                                            </Typography>
                                            <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                                                <FooterLink href="https://links.codedthemes.com/HTIBc" target="_blank" underline="none">
                                                    Digitization and AI
                                                </FooterLink>
                                                <FooterLink href="https://codedthemes.gitbook.io/berry/" target="_blank" underline="none">
                                                    Marco Trends
                                                </FooterLink>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Stack spacing={{ xs: 3, md: 5 }}>
                                            <Typography variant="h4" color={textColor} sx={{ fontWeight: 500 }}>
                                                Careers
                                            </Typography>
                                            <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                                                <FooterLink href="https://mui.com/store/license/" target="_blank" underline="none">
                                                    Open Positions
                                                </FooterLink>
                                                <FooterLink href="https://mui.com/store/license/" target="_blank" underline="none">
                                                    Life at 47NW
                                                </FooterLink>
                                            </Stack>
                                        </Stack>
                                    </Grid>*/}
                                    <Grid item xs={6} sm={3}/>
                                    <Grid item xs={6} sm={3}/>
                                    <Grid item xs={6} sm={3}/>
                                    {/*<Grid item xs={6} sm={3}>

                                        <Stack spacing={4.25}>
                                            <Typography variant="h4" color={textColor} sx={{ fontWeight: 500 }}>
                                                Follow Us
                                            </Typography>
                                            <Stack
                                               spacing={{ xs: 1.5, md: 2.5 }}
                                               direction="row"
                                            >
                                                <FooterLink href="https://mui.com/store/license/" target="_blank" underline="none">
                                                    <LinkedIn size="large" sx={{ color: 'text.secondary', '&:hover': { color: 'primary.main' } }} />
                                                </FooterLink>
                                                <FooterLink href="https://mui.com/store/license/" target="_blank" underline="none">
                                                    <Twitter sx={{ color: 'text.secondary', '&:hover': { color: 'primary.main' } }} />
                                                </FooterLink>
                                              
                                                <FooterLink href="https://mui.com/store/license/" target="_blank" underline="none">
                                                    <YouTube sx={{ color: 'text.secondary', '&:hover': { color: 'primary.main' } }} />
                                                </FooterLink>

                                                <FooterLink href="https://mui.com/store/license/" target="_blank" underline="none">
                                                    <Instagram sx={{ color: 'text.secondary', '&:hover': { color: 'primary.main' } }} />
                                                </FooterLink>
                                            </Stack>
        
                                        </Stack>
                                </Grid>*/}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Divider sx={dividerSX} />
            <Box sx={{ bgcolor: 'dark.dark', py: { xs: 3, sm: 1.5 } }}>
                <Container>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={{ xs: 1.5, sm: 1, md: 3 }}
                    >
                        <Typography color="text.secondary">
                            ©2023 47NW Capital  All Rights Reserved {/*| {' '} 
                            <Link href="https://codedthemes.com/" target="_blank" underline="hover">
                                Terms of service
                            </Link>
                            {' '} | {' '}
                            <Link href="https://codedthemes.com/" target="_blank" underline="hover">
                                Privacy statement
                            </Link>
                            {' '} | {' '}
                            <Link href="https://codedthemes.com/" target="_blank" underline="hover">
                                DCMA policy
                            </Link>
                            {' '} | {' '}
                            <Link href="https://codedthemes.com/" target="_blank" underline="hover">
                                Earnings disclaimer
                            </Link>
                            {' '} |*/}
                        </Typography>
                    </Stack>
                      
                </Container>
            </Box>
        </>
    );
};

export default FooterSection;
