/**
 * axios setup to use mock service
 */

import axios from 'axios';

const axiosBackendServices = axios.create({ baseURL: 'https://47nw-website-backend.azurewebsites.net/api/' });

// interceptor for http
axiosBackendServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosBackendServices;
